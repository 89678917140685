import React, { Component } from 'react';

import { TableColumnType, TableFilterType } from '../components';
import { HttpService } from '../services';
import { DeviceAlerts } from '../models';
export const DevicesContext = React.createContext();

export class DevicesProvider extends Component {
    state = {
        tableColumns: null,
        filterTableColumns: null,
        selectState: {}
    };

    componentDidMount() {
        Promise.all([
            HttpService.get('/Customer/GetAllCustomers'),
            HttpService.get('/Group'),
            HttpService.get('/Firmware/GetAllFirmwares'),
            HttpService.get('/DeviceType/GetAllDeviceTypes'),
            HttpService.get('/ProductLifecycleStage/GetAlProductLifecycleStages'),
            HttpService.get('/Firmware/GetApplicationFirmwares'),
            HttpService.get('/Firmware/GetGPSFirmwares'),
            HttpService.get('/Firmware/GetGSMFirmwares'),
            HttpService.get('/Firmware/GetRAMPFirmwares')
        ]).then(
            ([
                customersResponse,
                groupsResponse,
                firmwareResponse,
                deviceTypeResponse,
                productLifecycleStageResponse,
                applicationFirmwareResponse,
                gpsFirmwareResponse,
                gsmFirmwareResponse,
                rampFirmwareResponse
            ]) => {
                const customers = customersResponse.data;
                const groupNames = groupsResponse.data.map(x => x.groupName);
                const firmwares = firmwareResponse.data;
                const deviceTypes = deviceTypeResponse.data;
                const productLifecycleStages = productLifecycleStageResponse.data.map(x => x.stageName);
                const applicationFirmwareVersions = applicationFirmwareResponse.data.map(x => x.firmwareVersion);
                const gpsFirmwareVersions = gpsFirmwareResponse.data.map(x => x.firmwareVersion);
                const gsmFirmwareVersions = gsmFirmwareResponse.data.map(x => x.firmwareVersion);
                const rampFirmwareVersions = rampFirmwareResponse.data.map(x => x.firmwareVersion);

                const tableColumns = [
                    {
                        name: 'deviceId',
                        text: 'Device Id',
                        dataType: TableColumnType.Text,
                        filterType: TableFilterType.Text
                    },
                    {
                        name: 'serialNumber',
                        text: 'Serial No.',
                        dataType: TableColumnType.Text,
                        filterType: TableFilterType.Text
                    },
                    {
                        name: 'customerName',
                        text: 'Customer',
                        dataType: TableColumnType.Text
                    },
                    {
                        name: 'customerId',
                        text: 'Customer',
                        dataType: TableColumnType.Number,
                        filterType: TableFilterType.List,
                        filterList: customers,
                        filterListKey: 'id',
                        filterListValue: 'customerName',
                        filterOnly: true
                    },
                    {
                        name: 'dateRegistered',
                        text: 'Date Registered',
                        dataType: TableColumnType.DateTime,
                        filterType: TableFilterType.DateRange
                    },
                    {
                        name: 'deviceType',
                        text: 'Type',
                        dataType: TableColumnType.Text
                    },
                    {
                        name: 'deviceType',
                        text: 'Device Type',
                        dataType: TableColumnType.Text,
                        filterType: TableFilterType.List,
                        filterList: deviceTypes,
                        filterOnly: true
                    },
                    {
                        name: 'productLifecycleStageName',
                        text: 'Status',
                        dataType: TableColumnType.Text
                    },
                    {
                        name: 'productLifecycleStage',
                        text: 'Product Lifecycle Stage',
                        filterType: TableFilterType.List,
                        filterList: productLifecycleStages,
                        filterOnly: true
                    },
                    {
                        name: 'firmwareVersion',
                        text: 'Firmware',
                        dataType: TableColumnType.Text
                    },
                    {
                        name: 'firmwareVersion',
                        text: 'Firmware Version',
                        dataType: TableColumnType.Text,
                        filterType: TableFilterType.List,
                        filterList: firmwares,
                        filterListKey: 'id',
                        filterListValue: 'firmwareVersion',
                        filterOnly: true
                    },
                    {
                        name: 'metaDataValue',
                        text: 'Device data',
                        dataType: TableColumnType.Text,
                        filterType: TableFilterType.Text,
                        filterOnly: true
                    },
                    {
                        name: 'groups',
                        text: 'Device groups',
                        dataType: TableColumnType.Text,
                        filterType: TableFilterType.List,
                        filterList: groupNames,
                        filterMultiple: true,
                        filterOnly: true
                    },
                    {
                        name: 'deviceAlert',
                        text: 'Device alert',
                        filterType: TableFilterType.List,
                        filterListKey: 'value',
                        filterListValue: 'text',
                        filterList: [
                            { value: DeviceAlerts.NotConnectedSinceLastMonth, text: 'Not connected in last month' },
                            { value: DeviceAlerts.Tampered, text: 'Tampered' },
                            { value: DeviceAlerts.LessThanTenPercentBatteryLeft, text: 'Less than 10% battery left' },
                            { value: DeviceAlerts.BatteryExpiring, text: 'Battery expiring within warranty' }
                        ],
                        filterOnly: true
                    },
                    {
                        name: 'batteryExpiryPercentage',
                        text: 'Battery percentage',
                        filterType: TableFilterType.NumberRange,
                        filterOnly: true,
                        min: 0,
                        max: 100
                    },
                    {
                        name: 'simCardExpiry',
                        text: 'Sim Card Expiry Date',
                        filterType: TableFilterType.DateRange,
                        filterOnly: true
                    },
                    {
                        name: 'applicationFirmwareVersion',
                        text: 'Application Firmware Version',
                        dataType: TableColumnType.Text,
                        filterType: TableFilterType.List,
                        filterList: applicationFirmwareVersions,
                        filterOnly: true
                    },
                    {
                        name: 'gpsFirmwareVersion',
                        text: 'Gps Firmware',
                        dataType: TableColumnType.Text
                    },
                    {
                        name: 'gpsFirmwareVersion',
                        text: 'GPS Firmware Version',
                        dataType: TableColumnType.Text,
                        filterType: TableFilterType.List,
                        filterList: gpsFirmwareVersions,
                        filterOnly: true
                    },
                    {
                        name: 'gsmFirmwareVersion',
                        text: 'GSM Firmware Version',
                        dataType: TableColumnType.Text,
                        filterType: TableFilterType.List,
                        filterList: gsmFirmwareVersions,
                        filterOnly: true
                    },
                    {
                        name: 'rampFirmwareVersion',
                        text: 'RAMP Firmware Version',
                        dataType: TableColumnType.Text,
                        filterType: TableFilterType.List,
                        filterList: rampFirmwareVersions,
                        filterOnly: true
                    }
                ];
                const filterTableColumns = this.filterTableColumns(tableColumns);
                this.setState({ tableColumns, filterTableColumns, groups: groupsResponse.data, customers });
            }
        );
    }

    filterTableColumns = tableColumns => {
        return tableColumns.filter(tableColumn => tableColumn.filterType);
    };

    render() {
        const { children, authActions } = this.props;
        return (
            <DevicesContext.Provider
                value={{
                    devicesState: this.state,
                    devicesActions: {
                        updateSearchState: selectState => this.setState({ selectState }),
                        authActions
                    }
                }}
            >
                {children}
                {/* <dir className="float-right">
                    <pre>{JSON.stringify(this.state.selectState, null, 2)}</pre>
                </dir> */}
            </DevicesContext.Provider>
        );
    }
}

export const DevicesConsumer = DevicesContext.Consumer;
